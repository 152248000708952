import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const DemystifiedPage = () => {
    const article = {
        id: 'fff94694-d387-5b88-bdc2-856347f9eb79',
        title: 'Debt Consolidation Demystified: 5 Things Every Consumer Needs to Know',
        slug: '/demystified/',
        date: '2018-01-11T04:47:58.000Z',
        modified: '2021-11-01T20:05:32.000Z',
        excerpt: 'If you&#8217;re drowning in credit card bills and need a lifeline, consider debt consolidation; combining all that outstanding debt into one bill.',
        featured_image: {
            source_url: '/media/debt-consolidation-demystified.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 10,
        content: (
            <>
                <p>Are you in debt?</p>
                <p>You’re certainly not alone.</p>
                <p>According to the Federal Reserve, the total household debt in America rang up to $12.35 trillion at the end of Q3 in 2016, while credit card balances climbed $18 billion by the end of Q2.</p>
                <p>That’s a lot of zeros. No wonder Wall Street is raking it in. We’re paying a lot of interest on our debt.</p>
                <p>If you’re tired of being part of these statistics, it’s time to have a plan to get yourself out of debt.</p>
                <p>Being debt free means more than just having a zero credit card balance, though. It means having control of your finances so that you have enough to pay your expenses and save money.</p>
                <p>To become debt free, you need a plan that works for your financial situation. There are options available, including debt consolidation.</p>
                <LazyLoadImage src="/media/most-americans-are-stressed-about-money-stats-1.png" alt="most americans are stressed about money" />
                <p>But what is debt consolidation, exactly? And unlike that pose you’ve been practicing in yoga class, is it the right move for you?</p>
                <p>Is debt consolidation the right move for you?</p>
                <p>Let’s say you shopped your way through most of your 20s and are paying for it — literally — in your 30s.</p>
                <p>You’ve got the money to cover your minimum credit card payments each month, but of course, you’d rather pay it off more quickly. No one likes to put their hard-earned money towards fees.</p>
                <p>
                    A
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=demystified&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    could do the trick by consolidating your debt
                    {' '}
                    <em>AND</em>
                    {' '}
                    putting you at a much lower interest rate. Sounds like a great plan, right?
                </p>
                <LazyLoadImage src="/media/personal-loan-consolidation-graphic-2.png" alt="personal loan consolidation graphic" />
                <p>It could be, but like with all things personal finance, it is personal — it has to make sense for you and your budget.</p>
                <p>That’s right; we said budget — after all, debt consolidation will mean nothing if you&rsquo;re just going to go and rack up debt all over again.</p>
                <p>This guide to debt consolidation will help you figure out whether the money management solution makes sense for you.</p>
                <p>Here are four things you should know before you consolidate your debt:</p>
                <h2 id="1-what-is-debt-consolidation">1. What is debt consolidation?</h2>
                <p>As the term suggests, debt consolidation takes various debts—like those multiple credit card balances (ouch!)—and combines them into a single monthly payment, usually at a lower interest rate.</p>
                <p>Think of debt consolidation as &quot;getting all your debts under one roof,&quot; explains Kevin Gallegos, vice president of Phoenix operations at debt relief provider Freedom Financial Network.</p>
                <p>While it sounds like a good plan, debt consolidation is not a one-size-fits-all solution.</p>
                <p>“Debt consolidation may not the best debt relief method for people who are unable to make minimum payments on current debt,” says Gallegos.</p>
                <p>
                    Regardless of what the debt is—be it credit card debt,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=demystified&amp;sub2=student-loan" rel="noopener noreferrer" target="_blank">student loan</a>
                    {' '}
                    debt, or even a car loan—it is getting in your way of building a secure future, it’s in your best interest (no pun intended!) to pay it off as soon as possible. You want to be funding your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=demystified&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    and retirement accounts, not handing your money over to a lender or credit card company.
                </p>
                <p>Looking for help?</p>
                <ul className="list">
                    <li>
                        The US Department of Justice has a
                        {' '}
                        <a href="https://www.justice.gov/ust/list-credit-counseling-agencies-approved-pursuant-11-usc-111">list of credit counseling agencies that meet lofty legal standards</a>
                        {' '}
                        (a good thing when it comes to trusting someone to recommend advice for your money).
                    </li>
                    <li>
                        American Consumer Credit Counseling Professionals offers
                        {' '}
                        <a href="http://www.consumercredit.com/">free credit counseling and initial support</a>
                        . Be sure to the visit the FAQ on their website and if interested, call their toll-free number, +1&nbsp;(800)&nbsp;769‑3571, if you’d like to be connected with a professional credit counselor.
                    </li>
                </ul>
                <h2 id="2-debt-consolidation-is-different-from-debt-settlement">2. “Debt consolidation” is different from “debt settlement”</h2>
                <p>Debt settlement involves paying off creditors with a lump sum—usually less than the full amount owed, as determined by a third party.</p>
                <p>
                    Whole companies are dedicated to getting you and the original lenders to agree to new terms. The idea behind these debt settlement companies is that the status quo isn’t working. You aren’t able to pay your existing debt and thus, they’ll lose out on all the money — because you truly could go broke.
                    <LazyLoadImage src="/media/settlement-company-flowchart-6.png" alt="settlement company flowchart" />
                </p>
                <p>A debt settlement company gets both sides (you and the lender(s)) to agree to a new deal. You pay the debt settlement company, who takes a cut and makes sure the original lender gets their share.</p>
                <p>Debt settlement is a step before formally declaring bankruptcy, which has a huge and lasting impact on your credit score. Don&rsquo;t go there unless you’re out of options.</p>
                <p>After you have crunched the numbers and taken a hard look at your budget, and discovered that you simply can’t afford to pay your existing debt, then consider speaking with a licensed credit counselor (see the previous section).</p>
                <p><LazyLoadImage src="/media/1-in-4-ptsd-from-financial-stress-7.png" alt="1 in 4 PTSD from financial stress" /></p>
                <h2 id="3-theres-a-difference-between-good-debt-and-bad-debt">3. There’s a difference between good debt and bad debt</h2>
                <p>Owing money certainly isn’t the best feeling in the world. In fact, it might lead you to think all debt is bad debt — one of the biggest debt myths of all.</p>
                <p>
                    The three
                    {' '}
                    <em>worst</em>
                    {' '}
                    kinds of debt:
                </p>
                <ol className="list">
                    <li>Credit card — insane interest rates eat away at your income (and life)</li>
                    <li>Student — big numbers rack up without the lucrative careers to justify the cost</li>
                    <li>Auto — vanity gets in the way of sanity</li>
                </ol>
                <p>
                    But take what’s owed on your mortgage, for instance. That’s “good debt,” explains Anthony Piccone, president and CEO of 7th Level Mortgage, based in Philadelphia, Pennsylvania—especially in light of
                    {' '}
                    <a href="https://www.creditloan.com/mortgages/">today’s low-interest rates.</a>
                </p>
                <p>
                    While a mortgage is still a debt, it&rsquo;s not the same as being saddled with high-interest college or graduate
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=demystified&amp;sub2=school-loans" rel="noopener noreferrer" target="_blank">school loans</a>
                    .
                </p>
                <p>Besides the benefit of deducting mortgage interest on your tax returns each year, when adjusted for inflation, “[a mortgage] is the cheapest debt you can have, if you must,” Piccone says.</p>
                <p>
                    Paying a mortgage means you&rsquo;re investing in your future. Paying a credit card bill? Not so much. That&rsquo;s why it’s important to get rid of
                    {' '}
                    <em>that</em>
                    {' '}
                    debt as quickly as you can so you can focus on what matters most.
                </p>
                <h2 id="4-you-have-options-for-consolidating-debt">4. You have options for consolidating debt</h2>
                <p>
                    Not every debt consolidation solution requires taking out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=demystified&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    . Depending on how much you owe, your credit score, and whether you own or rent your home, there are alternative debt consolidation options.
                </p>
                <h3>Transfer debt to a new credit card with 0% APR</h3>
                <LazyLoadImage src="/media/transfer-debt-0-apr-9.png" alt="transfer debt 0 APR" />
                <p>If you have a credit card, you&rsquo;ve likely seen offers for low-to-no-interest balance transfers. A balance transfer takes the debt on one or more cards and moves it over to a single card so that you have one monthly payment.</p>
                <p>Balance transfers use your existing available credit line to combine your debt at a zero or low-interest rate. Because they don’t take very long for approval — most are completed within 7 to 10 business days—balance transfers are one of the easiest ways to consolidate debt.</p>
                <p>They aren’t completely risk-free, however, cautions Piccone.</p>
                <p>A higher credit card balance will have a negative impact on your credit score until you pay it off.</p>
                <p>
                    Worse, if you still have a balance after the low-interest period ends and the double-digit interest rates return, you’ll find yourself
                    {' '}
                    <em>further</em>
                    {' '}
                    behind the eight ball on debt again.
                </p>
                <p>The new credit card won’t make the old “over-spending” problems go away.</p>
                <p>Keep in mind that banks and credit card companies are businesses—and businesses need to make money. Any new credit card provider isn’t in the business of helping you out. Unless you are sure you can pay off your balance transfer within the timeframe of the offer (usually a year or more, depending on the card), you might end up paying even more in the long run.</p>
                <p>Pay off and then cut up cards to remove temptation.</p>
                <p>
                    Another thing to think about is canceling your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=demystified&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    after you pay them off. Consider keeping one or two for convenience, but if you’ve got half a dozen cards, that’s way too tempting. To avoid another cycle of debt in the future, you might want to cut yourself off from future spending sprees by—literally—cutting those cards up after canceling them.
                </p>
                <h3>Take out a personal loan</h3>
                <LazyLoadImage src="/media/personal-loan-graphic-11.png" alt="personal loan graphic" />
                <p>
                    As described earlier, taking out a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=demystified&amp;sub2=personal-loan" rel="noopener noreferrer" target="_blank">personal loan</a>
                    {' '}
                    can be a smarter way to pay off debt. Why pay 20% interest when you can pay 10% instead?
                </p>
                <p>Plus, personal loans can also be approved and funded quickly. But don’t think this is money for nothing. Besides the interest rate, which is set based on your personal credit score, a personal loan will often come with &quot;origination fees&quot; that equal about 5% of the total loan. It costs money to get money.</p>
                <p>To figure out if a personal loan is right for you, ask the following questions:</p>
                <ol className="list">

                    <li>
                        <em>What’s my average APR (referring to credit card debt)?</em>
                        {' '}
                        If you don’t know, stop and assess before considering a personal loan. You need to know this amount to figure out the right solution.
                    </li>

                    <li>
                        <em>Do I pay off a portion of the principal each month?</em>
                        {' '}
                        If you only pay off the interest, and not a piece of the principal debt, then you need to reassess your ability to budget before exploring a personal loan. You want to pay off the principal debt as fast as your budget can afford.
                    </li>

                    <li>
                        <em>Is my credit score between 300 and 649?</em>
                        {' '}
                        <a href="https://www.creditloan.com/bad-credit-loans/" target="_blank" rel="noreferrer">A “bad” credit score</a>
                        {' '}
                        may mean that the interest rate for a personal loan won’t be much better than the average one (from all your debts) you have now.
                    </li>

                    <li>
                        <em>How much time before I pay off my credit card?</em>
                        {' '}
                        If you find that it’s going to take you a few years to pay off your debt, and your personal loan interest rate is lower than the average APR now, then a loan may make sense. Creditloan offers
                        {' '}
                        <a href="https://www.creditloan.com/financial-calculators/debtpayoff2/">an easy tool to calculate when you&rsquo;ll pay off multiple cards.</a>
                    </li>
                </ol>
                <h3>Borrow against your home equity</h3>
                <LazyLoadImage src="/media/home-equity-graphic-16.png" alt="home equity graphic" />
                <p>
                    Just like you can take out a personal loan to help pay off debt more affordably, you can also borrow money from the bank based on the value of your home. Sometimes referred to as a “second mortgage,”
                    {' '}
                    <a href="https://www.creditloan.com/mortgages/">refinancing your mortgage</a>
                    {' '}
                    can free up much-needed cash to pay off debt.
                </p>
                <p>For instance, you may own a home worth $100,000 according to the bank and market value.</p>
                <p>Based on the value of your home, the bank would loan you money at a lower interest rate than what you’re paying on your existing debt.</p>
                <p>You’d use that money to pay off the “worst debts.” Afterward, you would be in debt to the bank—but with a lower interest amount per month than before.</p>
                <LazyLoadImage src="/media/graph-how-people-cope-with-stress-17.png" alt="graph how people cope with stress" />
                <p>Again, if you don’t have the problem that you got you into this position of considering debt consolidation under control, then you should not lightly pursue this option. You don’t want to expose the roof over your head unnecessarily. If your home’s value dropped while you’re paying off the loan, then you&rsquo;d still owe what you and bank agreed on when you refinanced, making this a risky move.</p>
                <h3>
                    <strong>Borrow from your friends and family</strong>
                    <LazyLoadImage src="/media/borrow-from-friends-family-18.png" alt="borrow from friends family" />
                </h3>
                <p>This is a very sensitive option for many emotional and personal reasons. The concept here is that you pay your friends and/or family a lower interest rate than what you’re paying on your debts now.</p>
                <p>If you thought holidays were awkward now, then after borrowing money from your uncle, for example, you’ll squirm at the thought of family.</p>
                <h2 id="5-how-to-prepare-for-life-after-debt">5. How to prepare for life after debt</h2>
                <LazyLoadImage src="/media/life-after-debt-graphic-19.png" alt="life after debt graphic" />
                <p>You’ll feel an enormous sense of relief once that final loan payment is made, but that’s when your real work begins.</p>
                <p>Staying out of debt can prove even harder than paying it off, which is why it’s important to break the cycle of living paycheck to paycheck with discipline, says Piccone — lots and lots of discipline.</p>
                <p>
                    “I caution my borrowers that they have to take stock and realize they cannot repeat [financial mistakes] in the years to come,” says Piccone. “They need to curtail or eliminate impulse buying, use the money they are saving to reestablish themselves, and replace debt with
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=demystified&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    accounts or other assets.”
                </p>
                <LazyLoadImage src="/media/exercise-to-cope-with-stress-21.png" alt="exercise to cope with stress" />
                <p>If you’d like to reduce your credit card debt to one monthly bill, then debt consolidation is a viable option.</p>
                <p>
                    With a new budget that allows you to pay down debt
                    {' '}
                    <em>and</em>
                    {' '}
                    have some wiggle room to enjoy life, you&rsquo;ll eventually be able to show those
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=demystified&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    who&rsquo;s boss.
                </p>
                <p>What did you find the most surprising or unexpected about debt consolidation? Let us know in the comments below.</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default DemystifiedPage;
